
































import {Component, Vue, Prop, Watch} from "vue-property-decorator";

@Component({
    components: {}
})
export default class LanguageSettings extends Vue {
    @Prop() readonly object?: any;

    language = 'en'
    languages = require('language-list')().getData();
    fields = [
        {
            label: "View Post Link",
            default: "View on Instagram",
            field: "view_on_instagram"
        },
        {
            label: "View Product Button",
            default: "View Product",
            field: "product_action"
        },
        {
            label: "View Collection Button",
            default: "View Collection",
            field: "collection_action"
        },
        {
            label: "Add To Cart Button",
            default: "Add To Cart",
            field: "add_to_cart"
        },
        {
            label: "Added To Cart Text",
            default: "Added To Cart",
            field: "item_added_to_cart"
        },
        {
            label: "Item Unavailable",
            default: "Unavailable",
            field: "unavailable"
        },
        {
            label: "View Cart Button",
            default: "View Cart",
            field: "view_cart"
        },
        {
            label: "Quantity Field",
            default: "Quantity",
            field: "quantity"
        },
    ]
    @Watch('language', {immediate: true})
    onLanguage(language: string){
        if (this.object.locale[language] === undefined) {
            this.$set(this.object.locale, language, {});
        }
    }
}
