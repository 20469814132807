





















































































































































import {Component, Vue, Prop} from "vue-property-decorator";

@Component
export default class PostPopupPreview extends Vue {
    @Prop() readonly object?: any;

    get style() {
        return {
            '--dialog-background': this.object.post_dialog_color,
            '--dialog-color': this.object.post_dialog_font_color,
            '--tag-background': this.object.tag_action_button_color,
            '--tag-color': this.object.tag_action_text_color,
        }
    }
}
