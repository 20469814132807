




















import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component
export default class SearchField extends Vue {
    @Prop({type: String, default: ''}) value?: string;

    text?: string = '';

    emit() {
        this.$emit('input', this.text);
    }

    clear() {
        this.text = '';
        this.$nextTick(() => {
            this.emit();
        })
    }

    @Watch('value')
    onValue(value: string) {
        this.text = value;
    }

    created() {
        this.text = this.value;
    }
}
