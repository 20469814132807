import Page from './Page.vue';
import OpenSupportTicketCard from "./OpenSupportTicketCard.vue";
import Checklist from "./Checklist.vue";
import OverlayLoader from "./OverlayLoader.vue";
import Pagination from "./Pagination.vue";
import Loader from "./Loader";
import Disconnected from "./Disconnected.vue";

// @ts-ignore
import ColorPicker from '@uplinkly/controls/src/ColorPicker';
// @ts-ignore
import AceEditor from '@uplinkly/controls/src/AceEditor';
// @ts-ignore
import SitePreview from '@uplinkly/controls/src/SitePreview';

import TitleBar from "@uplinkly/bridge/src/vue/TitleBar";
import ContextualSaveBar from "@uplinkly/bridge/src/vue/ContextualSaveBar";
import NavigationMenu from "@uplinkly/bridge/src/vue/NavigationMenu";

export default function (Vue: any) {
    Vue.component('Page', Page);
    Vue.component('OpenSupportTicketCard', OpenSupportTicketCard);
    Vue.component('OverlayLoader', OverlayLoader);
    Vue.component('Pagination', Pagination);
    Vue.component('Checklist', Checklist);
    Vue.component('Loader', Loader);
    Vue.component('Disconnected', Disconnected);

    Vue.component('ColorPicker', ColorPicker);
    Vue.component('AceEditor', AceEditor);
    Vue.component('SitePreview', SitePreview);

    Vue.component('TitleBar', TitleBar);
    Vue.component('ContextualSaveBar', ContextualSaveBar);
    Vue.component('NavigationMenu', NavigationMenu);
}