






import {Component, Vue, Prop} from 'vue-property-decorator';

@Component
export default class OverlayLoader extends Vue {
    @Prop(Boolean)
    loading?: boolean;

    @Prop({type: Boolean, default: true})
    indeterminate!: boolean;

    @Prop({type: Boolean, default: true})
    fade!: boolean;

    @Prop({type: String, default: '#4caf50'})
    color!: string;

    @Prop({type: String, default: 'rgba(255, 255, 255, 0.6)'})
    background!: string;

    @Prop({type: Number, default: 100})
    size!: number;

    @Prop({type: Number, default: 6})
    width!: number;

    @Prop({type: Number, default: 0})
    value!: number;
}
