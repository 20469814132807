













import {Component, Vue, Prop} from "vue-property-decorator";

interface ChecklistItem {
    icon: string
    text: string
}

@Component({})
export default class Checklist extends Vue {
    @Prop() public items: ChecklistItem[] | undefined;

    getIcon(item: ChecklistItem) {
        switch (item.icon) {
            case 'tick':
                return 'mdi-check-circle'
            case 'cross':
                return 'mdi-close-circle'
            case 'plus':
                return 'mdi-plus-circle'
            case 'minus':
                return 'mdi-minus-circle'
        }
    }

    getIconColor(item: ChecklistItem){
        switch (item.icon) {
            case 'tick':
                return 'green'
            case 'cross':
                return 'red'
            case 'plus':
                return 'green'
            case 'minus':
                return 'red'
        }
    }
}
