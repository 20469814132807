












































import {Component, Vue} from "vue-property-decorator";

@Component
export default class Home extends Vue {
    get enabled() {
        return this.configuration?.enabled;
    }

    get connected() {
        return this.accounts?.results?.length > 0;
    }

    get ready() {
        return this.enabled && this.connected;
    }

    get loading() {
        return !(this.configuration && this.accounts);
    }

    get checklist() {
        return [
            {icon: 'tick', text: 'Installed Into Shopify'},
            {icon: 'tick', text: 'Plan Selected'},
            {icon: this.connected ? 'tick' : 'cross', text: 'Instagram Connected'},
            {icon: this.enabled ? 'tick' : 'cross', text: 'Configured'}
        ]
    }

    async connect() {
        const response = await this.$api.get('/accounts/authorize_url/');
        this.$bridge.Redirect.url(response.url, true);
    }

}
