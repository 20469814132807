
























import {Component, Vue} from "vue-property-decorator";
import Post from "@/components/Post.vue";
import EditPostDialog from "@/dialogs/EditPostDialog.vue";

@Component({
    components: {EditPostDialog, Post}
})
export default class TagEditor extends Vue {
    account: any = null;
    loading: boolean = false;
    posts: any = null;

    get username() {
        return this.account?.username;
    }

    async load() {
        try {
            this.loading = true;
            this.posts = this.$api.paginator(`/accounts/${this.$route.params.id}/posts/`);
            this.posts.get();
            this.account = await this.$api.get(`/accounts/${this.$route.params.id}/`);
            // @ts-ignore
            await this.posts.get();
        } finally {
            this.loading = false;
        }
    }

    created() {
        this.load();
    }
}
