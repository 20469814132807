















































import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {mapGetters} from "vuex";
import TaggedItem from "@/components/TaggedItem.vue";
import {mixins} from "vue-class-component";
import {Post as PostMixin} from "@/mixins/Post";
import SelectProductDialog from "@/dialogs/SelectProductDialog.vue";
import SelectCollectionDialog from "@/dialogs/SelectCollectionDialog.vue";
dayjs.extend(relativeTime);

@Component({
    components: {SelectCollectionDialog, SelectProductDialog, TaggedItem},
    computed: {
    ...mapGetters([
        'user'
    ])
  }
})
export default class EditPostDialog extends mixins(PostMixin) {
    @Prop() account?: any;

    post: any = {};
    loading: boolean = false;
    dialog: boolean = false;

    async remove(index: number){
        const tag = this.post.meta.tags[index];
        if (await this.$bridge.Confirm.confirm(
            'Confirm Removal',
            `Are you sure you wish to remove ${tag.title}?`,
            {
                confirm: {
                    label: 'Delete',
                    style: 'danger',
                }
            }
        )) {
            this.post.meta.tags.splice(index, 1);
        }
    }
    async open(post: any){
        this.post = post;
        this.dialog = true;
        try{
            this.loading = true;
            let temp = await this.$api.get(`/accounts/${this.$route.params.id}/posts/${this.post.id}/`);
            temp.meta.tags = Array.isArray(temp.meta.tags) ? temp.meta.tags : [];
            this.post = temp;
        } finally {
            this.loading = false;
        }
    }

    async save() {
        try{
            this.loading = true;
            this.post = await this.$api.patch(`/accounts/${this.$route.params.id}/posts/${this.post.id}/`, this.post);
            this.close();
        } finally {
            this.loading = false;
        }
    }

    async addProduct(){
        let product = await (this.$refs.selectProduct as SelectProductDialog).show();
        if(product){
            this.post.meta.tags.push({
                'type': 'product',
                'id': product.id,
                'title': product.title,
                'handle': product.handle,
                'image': product.image
            });
        }
    }

    async addCollection(){
        let collection = await (this.$refs.selectCollection as SelectCollectionDialog).show();
        if(collection){
            this.post.meta.tags.push({
                'type': 'collection',
                'id': collection.id,
                'title': collection.title,
                'handle': collection.handle,
                'image': collection.image
            });
        }
    }

    close() {
        this.dialog = false;
    }
}
