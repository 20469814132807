import Vue from 'vue'
import {VSkeletonLoader} from "vuetify/lib/components";

// @ts-ignore
export default Vue.extend({
    name: "Loader",
    functional: true,
    props: {
        loading: Boolean,
        error: Boolean,
        loadingSkeleton: Object
    },
    // @ts-ignore
    render(createElement, context) {
        const props = context.props;
        const slots = context.slots();

        if (props.loading) {

            if (props.loadingSkeleton) {
                return createElement(VSkeletonLoader, {
                    props: props.loadingSkeleton
                })
            }
            return slots.loading;
        } else if (props.error) {
            return slots.error;
        }
        return slots.default;
    }
})