import {mapGetters} from "vuex";

declare module 'vue/types/vue' {
    interface Vue {
        user: any,
        configuration: any,
        configurationOptions: any,
        accounts: any,
    }
}

export default function (Vue: any) {
    Vue.mixin({
        computed: mapGetters(['user', 'configuration', 'configurationOptions', 'accounts'])
    })
}