import { render, staticRenderFns } from "./PostPopupPreview.vue?vue&type=template&id=2b2d0b70&scoped=true&"
import script from "./PostPopupPreview.vue?vue&type=script&lang=ts&"
export * from "./PostPopupPreview.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b2d0b70",
  null
  
)

export default component.exports