






































import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class InstagramAccountPanel extends Vue {
    @Prop() account?: any;

    get initials() {
        return this.account.username.split(/\s+/)
            .map((name: string) => name[0])
            .join('')
            .substr(0, 2)
            .toUpperCase();
    }

    get status(){
        switch(this.account.status){
            case 'ready':
                return 'Connected to Instagram';
            case 'failed':
                return 'Connection failed. Try connecting account again';
            case 'disabled':
                return 'Account disabled';
        }
        return 'Unknown status';
    }
}
