































































































import {Component, Vue, Prop} from "vue-property-decorator";
import AddToCartPopupPreview from "@/pages/Settings/components/AddToCartPopupPreview.vue";

@Component({
    components: {AddToCartPopupPreview}
})
export default class ProductSettings extends Vue {
    @Prop() readonly object?: any;

    showUnavailable: boolean = false

    get showPopupOptions() {
        return this.object.post_action === 'popup';
    }

    get showProductPopupOptions() {
        return this.showPopupOptions && this.object.product_action === 'popup';
    }
}
