














import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class TaggedItem extends Vue {
    @Prop() tag: any;
}
