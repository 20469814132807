var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',[_c('TitleBar',{attrs:{"title":"Support","primary":{
            label: 'Create Support Ticket',
            click: _vm.createSupportTicket
        }}}),_c('v-skeleton-loader',{attrs:{"elevation":_vm.loading ? 2 : 0,"type":"table","types":{
        'table': 'table-heading, table-thead, table-tbody',
        'table-heading': 'text',
        'table-thead': 'heading@4',
        'table-tbody': 'table-row-divider@1',
        'table-row-divider': 'divider, table-row',
        'table-row': 'table-cell@4'
    },"loading":_vm.loading}},[_c('v-card',[_c('v-card-title',[_vm._v("Your Support Tickets")]),_c('v-card-text',[(_vm.tickets)?_c('v-data-table',{attrs:{"headers":[
                                {
                                    text: 'Description',
                                    align: 'left',
                                    value: 'subject',
                                },
                                {
                                    text: 'Updated',
                                    align: 'left',
                                    value: 'updated',
                                },
                                {
                                    text: 'Status',
                                    align: 'left',
                                    value: 'status',
                                },
                                {
                                    text: '',
                                    value: 'actions',
                                    sortable: false,
                                    align: 'right'
                                }
                            ],"items":_vm.tickets,"hide-default-footer":true,"items-per-page":100000},scopedSlots:_vm._u([{key:"item.updated",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.localDate(item.updated))+" ")]}},{key:"item.actions",fn:function(ref){
                            var item = ref.item;
return [_c('v-btn',{attrs:{"color":"default","small":"","loading":item.loading},on:{"click":function($event){return _vm.open(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Open ")],1)]}}],null,false,4008137903)}):_vm._e()],1)],1)],1),_c('CreateSupportTicketDialog',{ref:"createTicket",on:{"created":_vm.getTickets}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }