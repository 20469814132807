import {API} from "@uplinkly/api";
import Bridge from "@uplinkly/bridge/src/index";
import api from '@/core/api';
import bridge from '@/core/bridge';

declare module 'vue/types/vue' {
    interface Vue {
        $api: API,
        $bridge: Bridge
    }
}

export default function (Vue: any) {
    if (!Vue.prototype['$api']) {
        Object.defineProperty(Vue.prototype, '$api', {
            get() {
                return api;
            }
        })
    }
    if (!Vue.prototype['$bridge']) {
        Object.defineProperty(Vue.prototype, '$bridge', {
            get() {
                return bridge;
            }
        })
    }
}