





























import {Component, Vue} from "vue-property-decorator";
import InstagramAccountPanel from "./components/InstagramAccountPanel.vue";
import {InstagramAccount} from "@/types";

@Component({
    components: {InstagramAccountPanel}
})
export default class Accounts extends Vue {
    connectingToolbar: boolean = false;
    connectingButton: boolean = false;

    async connectToolbar() {
        try {
            this.connectingToolbar = true;
            await this.connect();
        } finally {
            this.connectingToolbar = false;
        }
    }

    async connectButton() {
        try {
            this.connectingButton = true;
            await this.connect();
        } finally {
            this.connectingButton = false;
        }
    }

    async connect() {
        const response = await this.$api.get('/accounts/authorize_url/');
        this.$bridge.Redirect.url(response.url, true);
    }

    async remove(account: InstagramAccount) {
        if (await this.$bridge.Confirm.confirm(
            'Confirm Account Removal',
            `Are you sure you wish to remove ${account.username}?`,
            {
                confirm: {
                    label: 'Delete',
                    style: 'danger',
                    close: false
                }
            }
        )) {
            try {
                await this.$store.dispatch('deleteAccount', account);
            } finally {
                this.$bridge.Confirm.close();
            }
        }
    }
}
