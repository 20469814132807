













































import {Component, Vue, Watch} from "vue-property-decorator";
import PostSettings from "./components/PostSettings.vue";
import ProductSettings from "./components/ProductSettings.vue";
import CSSSettings from "./components/CSSSettings.vue";
import LanguageSettings from "./components/LanguageSettings.vue";

@Component({
    components: {LanguageSettings, CSSSettings, PostSettings, ProductSettings}
})
export default class Settings extends Vue {
    tab: string = 'post';

    object: any = {}
    loading: boolean = false;
    saving: boolean = false;
    disabling: boolean = false;

    get dirty() {
        return JSON.stringify(this.object) !== JSON.stringify(this.configuration);
    }

    get saveDisabled(){
        if(this.dirty){
            return false;
        }
        return this.configuration.enabled;
    }

    get saveText(){
        if(this.configuration.enabled){
            return 'Save';
        }
        if(this.dirty){
            return 'Save and Enable'
        }else{
            return 'Enable';
        }
    }

    async load() {
        if (!this.configurationOptions) {
            try {
                this.loading = true;
                await this.$store.dispatch('getConfigurationOptions');
            } finally {
                this.loading = false;
            }
        }
    }

    async save() {
        try {
            this.saving = true;
            await this.$store.dispatch('saveConfiguration', {...this.object, enabled: true});
        } finally {
            this.saving = false;
        }
    }

    async disable(){
        try {
            this.disabling = true;
            await this.$store.dispatch('saveConfiguration', {enabled: false});
        } finally {
            this.disabling = false;
        }
    }

    discard() {
        this.object = JSON.parse(JSON.stringify(this.configuration));
    }

    created() {
        this.load();
    }

    @Watch('configuration', {deep: true, immediate: true})
    onConfiguration(configuration: any) {
        this.object = JSON.parse(JSON.stringify(configuration));
    }

    @Watch('dirty', {deep: true, immediate: true})
    onDirty(dirty: boolean) {
        if (dirty) {
            this.$bridge.ContextualSaveBar.show();
        } else {
            this.$bridge.ContextualSaveBar.hide();
        }
    }
}
