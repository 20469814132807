import Vue from 'vue'
import Component from 'vue-class-component'
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

@Component
export class Post extends Vue {
    post?: any

    get backgroundImageStyle() {
        return {
            "background-image": `url(${this.mediaURL})`
        }
    }

    get isImage() {
        return this.post?.media_type === 'IMAGE';
    }

    get isCarousel() {
        return this.post?.media_type === 'CAROUSEL_ALBUM';
    }

    get isVideo() {
        return this.post?.media_type === 'VIDEO';
    }

    get mediaURL() {
        return this.post?.media_url;
    }

    get username() {
        return this.post?.username;
    }

    get caption() {
        return this.post?.caption;
    }

    get timestamp() {
        return this.post?.timestamp;
    }

    get ago() {
        return dayjs(this.post?.timestamp).fromNow();
    }

    get permalink() {
        return this.post?.permalink;
    }

    get carouselImages() {
        return this.post?.children.data.map((child: any) => child.media_url);
    }
}