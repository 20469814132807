


















import Component, { mixins } from 'vue-class-component'
import {Prop} from "vue-property-decorator";
import {Post as PostMixin} from '@/mixins/Post';

@Component
export default class Post extends mixins(PostMixin) {
    @Prop() post?: any;
}
