













import {Component, Vue, Prop} from 'vue-property-decorator';

@Component
export default class Pagination extends Vue {
    @Prop() paginator: any;
}
