



































































import {Component, Vue} from "vue-property-decorator";
import TitleBar from "@uplinkly/bridge/src/vue/TitleBar";
import CreateSupportTicketDialog from "@/dialogs/CreateSupportTicketDialog.vue";
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
dayjs.extend(utc);
dayjs.extend(timezone);

@Component({
    components: {CreateSupportTicketDialog, TitleBar}
})
export default class Support extends Vue {
    loading: boolean = false;
    tickets: any[] | null = null;
    height: any = 0;
    url?: string = 'ad';

    get style() {
        if (this.height) {
            return {
                height: `${this.height}px`,
                display: this.height ? 'block' : 'none'
            }
        } else {
            return {};
        }
    }

    localDate(date: string){
        const utc = dayjs(date + 'Z', "YY-MM-DD H:i:s");
        return utc.format('YYYY-DD-MM H:mm');
    }

    async getUrl() {
        let response = await this.$api.get('/support/sso');
        this.url = response.url;
    }

    async getTickets() {
        try {
            this.loading = true;
            const response = await this.$api.get('/support/tickets');
            this.tickets = response.map((ticket: any) => ({loading: false, ...ticket}));
        }finally{
            this.loading = false;
        }
    }

    async open(ticket: any) {
        try {
            ticket.loading = true;
            let response = await this.$api.get('/support/sso', {params: {ticket_id: ticket.id}});
            this.$bridge.Redirect.url(response.url, true);
        }finally{
            ticket.loading = false;
        }
    }

    createSupportTicket(){
        (this.$refs.createTicket as any).show();
    }

    mounted() {
        this.getTickets();
        this.getUrl();
        window.addEventListener(
            "message",
            e => {
                this.height = e.data.height;
            },
            false
        );
    }
}
