









































































































































































































import {Component, Vue, Prop} from "vue-property-decorator";

@Component({
    components: {}
})
export default class AddToCartPopupPreview extends Vue {
    @Prop() readonly object?: any;
    @Prop(Boolean) readonly showUnavailable: boolean = false;

    get style() {
        return {
            '--dialog-background': this.object.product_dialog_color,
            '--dialog-color': this.object.product_dialog_font_color,
            '--tag-background': this.object.tag_action_button_color,
            '--tag-color': this.object.tag_action_text_color,
            '--add-to-cart-background': this.object.add_to_cart_button_color,
            '--add-to-cart-color': this.object.add_to_cart_text_color,
            '--unavailable-background': this.object.unavailable_button_color
        }
    }
}
