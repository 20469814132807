


































































































































































































































import {Component, Vue} from "vue-property-decorator";

@Component
export default class Embed extends Vue {
    loadingFeed: boolean = false
    style: string = 'grid'
    margin: number = 0
    columns_desktop: number = 5
    columns_mobile: number = 1
    rows_desktop: number = 1
    rows_mobile: number = 1

    tagsText: string = ''

    styles: any[] = [
        {
            value: 'grid',
            text: 'Grid',
        },
        {
            value: 'slider',
            text: 'Slider',
        }
    ]
    devices: object = {
        'desktop': 900,
        'mobile': 320
    }
    device: string = 'desktop'
    step: number = 1
    accountId: string = ''

    get tags(){
        if(this.tagsText && this.tagsText !== ''){
            return this.tagsText.split(',').map(tag => tag.trim()).join(',');
        }
        return '';
    }

    get code() {
        return `<div class="instagram-feed-wrapper" data-settings='${this.settings}'>`
            + '<div class="instagram-feed"></div>'
            + '</div>';
    }

    get src() {
        const host = (new URL(this.$api.apiRoot, document.location.href)).toString();
        return (new URL(`/public/${this.user.domain}/instagram-feed.js`, host)).toString();
    }

    get settings() {
        const settings = {
            account: this.accountId,
            tags: this.tags,
            style: this.style,
            margin: this.margin,
            columns_desktop: this.columns_desktop,
            rows_desktop: this.rows_desktop,
            columns_mobile: this.columns_mobile,
            rows_mobile: this.rows_mobile,
        };
        return JSON.stringify(settings);
    }

    select() {
        const range = document.createRange();
        const sel = window.getSelection();
        range.selectNodeContents((this.$refs.code as any));
        sel!.removeAllRanges();
        sel!.addRange(range);
    }

    copy() {
        this.select();
        document.execCommand("copy");
        this.$bridge.Toast.display('Copied to Clipboard');
    }

    processMessage(e: any) {
        switch (e.type) {
            case'instagram-feed:loading':
                this.loadingFeed = true;
                break;
            case'instagram-feed:loaded':
                this.loadingFeed = false;
                break;
            case'instagram-feed:load-failed':
                this.loadingFeed = false;
                break;
        }
    }

    created() {
        this.accountId = this.$route.params.id;
        if (this.accounts.results.length === 0) {
            this.$router.replace({name: 'home'});
        }
    }

}
