
























































import Component from "vue-class-component";
import {Vue, Watch} from "vue-property-decorator";
import SearchField from "@/components/SearchField.vue";
@Component({
    components: {SearchField}
})
export default class SelectCollectionDialog extends Vue {
    loading: boolean = false
    dialog: boolean = false
    resolve: any = null
    collections: any = null
    search: string = ''

    show() {
        this.search = '';
        this.dialog = true;
        this.collections = this.$api.paginator(`/collections/`);
        this.collections.get();
        return new Promise<any>(resolve => {
            this.resolve = resolve;
        })
    }

    async select(product: any) {
        try {
            this.loading = true;
            let fullCollection = await this.$api.get(`collections/${product.id}/`);
            this.resolve(fullCollection);
            this.close();
        } finally {
            this.loading = false;
        }
    }

    cancel() {
        this.resolve(null);
        this.close();
    }

    close() {
        this.search = '';
        this.collections.clear();
        this.dialog = false;
    }

    @Watch('search')
    onSearch(search: string) {
        this.collections.get({search});
    }
}
