import Vue from 'vue'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import router from '@/core/router'
import bridgeRouter from '@uplinkly/bridge/src/vue/router';
import store from '@/core/store'
import bridge from "@/core/bridge";
import api from "@/plugins/api";
import state from "@/plugins/state";
import components from "@/components";
// @ts-ignore
import Dayjs from 'vue-dayjs';

import '@/assets/style.css';
import checkEnvAndRedirect from "@/core/env";

checkEnvAndRedirect();

if(bridge.ready) {
  Vue.config.productionTip = false

  Vue.use(Dayjs, {lang: 'en'});
  Vue.use(api);
  Vue.use(state);
  Vue.use(components);
  Vue.use(bridgeRouter);

  new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}