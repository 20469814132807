














import {Component, Vue} from "vue-property-decorator";
import InstallationStatus from "./components/InstallationStatus.vue";

@Component({
    components: {InstallationStatus}
})
export default class Home extends Vue {}
