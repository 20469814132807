var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"disabled":_vm.loading,"width":"800px"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cancel.apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-toolbar',{attrs:{"color":"secondary","flat":""}},[_c('span',{staticClass:"white--text headline"},[_vm._v(" Select Product ")])]),_c('v-card-text',{staticClass:"edit-dialog-content"},[_c('v-container',[_c('div',{staticClass:"pt-2 relative"},[_c('SearchField',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"headers":[
                                {
                                    text: 'Product',
                                    align: 'left',
                                    value: 'title',
                                },
                                {
                                    text: '',
                                    value: 'actions',
                                    sortable: false,
                                    align: 'right'
                                }
                            ],"items":_vm.products.results,"hide-default-footer":true,"items-per-page":100000},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                            var item = ref.item;
return [_c('v-btn',{attrs:{"color":"default","small":""},on:{"click":function($event){return _vm.select(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v(" Select ")],1)]}}])}),_c('OverlayLoader',{attrs:{"loading":_vm.loading,"background":"rgba(255, 255, 255, 0.6)"}}),_c('Pagination',{attrs:{"paginator":_vm.products}})],1)])],1),_c('v-divider'),_vm._t("actions",function(){return [_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.cancel}},[_vm._v("Cancel")])],1)]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }