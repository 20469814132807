












































































import Component from "vue-class-component";
import {Vue} from "vue-property-decorator";
@Component
export default class CreateSupportTicketDialog extends Vue {
    dialog: boolean = false;
    errors: any = {};
    loading: boolean = false;
    password: string = '';
    subject: string = '';
    description: string = '';
    topic: number = 62;
    ticketId?: null = null;

    show() {
        this.errors = {};
        this.loading = false;
        this.password = '';
        this.subject = '';
        this.description = '';
        this.ticketId = null;
        this.dialog = true;
    }

    get passwordRequired() {
        return this.user?.password_enabled;
    }

    validate() {
        let errors: any = {};

        if (this.passwordRequired && !this.password) {
            errors.password = 'Please enter your store password'
        }

        if (!this.subject) {
            errors.subject = 'Please enter a subject'
        }

        if (!this.description) {
            errors.description = 'Please enter a detailed description of the problem'
        }

        this.errors = errors;
        return !Object.keys(errors).length;
    }

    async create() {
        if (!this.validate()) {
            return;
        }

        try {
            this.loading = true;
            let response = await this.$api.post('/support/tickets/', {
                topic: this.topic,
                subject: this.subject,
                message: this.password ? `Password: ${this.password}\n\n${this.description}` : this.description
            });
            this.ticketId = response.number;
            this.$emit('created');
        } finally {
            this.loading = false;
        }
    }
}
