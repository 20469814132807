function checkEnvAndRedirect(){
    const url = new URL(document.location.href);
    const env = url.searchParams.get('env');
    if(env && env.endsWith('.uplinkly.com')){
        url.host = env;
        url.searchParams.delete('env')
        document.location.href = url.toString();
    }
}

export default checkEnvAndRedirect;