








import {Component, Vue} from 'vue-property-decorator';
import channel from "@/core/channel";

@Component
export default class App extends Vue {
    loading: boolean = false;
    error: boolean = false;
    links: any[] = [
        {label: 'Home', destination: '/home'},
        {label: 'Accounts', destination: '/accounts'},
        {label: 'Embed', destination: '/embed'},
        {label: 'Settings', destination: '/settings'},
        {label: 'FAQs', destination: '/faqs'},
        {label: 'Support', destination: '/support'}
    ]

    async load() {
        try {
            this.loading = true;
            await Promise.all([
                this.$store.dispatch('getUser'),
                this.$store.dispatch('getConfiguration'),
                this.$store.dispatch('getAccounts')
            ]);
        } catch (e) {
            if(e.statusCode !== 401) {
                this.error = true;
            }
        } finally {
            this.loading = false;
        }
    }

    created() {
        this.$api.addEventListener('retry', () => {
            this.$bridge.Toast.display('An Error Occurred. Retrying...');
        });

        this.$api.addEventListener('failed', () => {
            this.$bridge.Toast.error('An Error Occurred. Please try again.');
        });

        channel.addEventListener('message', (event: MessageEvent) => {
            if (event.data.message === 'instagram-feed:account-added') {
                this.$bridge.Toast.display(`${event.data.account} Connected`);
                this.$store.dispatch('getAccounts')
            }
        })

        this.load();
    }
}
