import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from "@/pages/Home";
import Settings from "@/pages/Settings";
import Support from "@/pages/Support";
import Accounts from "@/pages/Accounts";
import TagEditor from "@/pages/TagEditor";
import Embed from "@/pages/Embed";
import FAQS from "@/pages/FAQS";

Vue.use(VueRouter);

const routes = [
    {
        path: '/home',
        name: 'home',
        component: Home
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings
    },
    {
        path: '/accounts',
        name: 'accounts',
        component: Accounts
    },
    {
        name: 'tag-editor',
        path: '/accounts/:id',
        component: TagEditor,
    },
    {
        name: 'embed',
        path: '/embed/:id?',
        component: Embed
    },
    {
        path: '/support',
        name: 'support',
        component: Support
    },
    {
        path: '/faqs',
        name: 'faqs',
        component: FAQS
    },
    {
        path: "*",
        redirect: '/home'
    }
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;
