import {API} from "@uplinkly/api";
import bridge from "@/core/bridge";

const apiURL = process.env.VUE_APP_API_URL || '/api/';

const api = new API(apiURL, () => bridge.getSessionToken());

api.addEventListener('failed', (event: Event) => {
    if((event as any).statusCode === 401){
        event.stopImmediatePropagation();
        const url = new URL('/auth/install', api.apiURL);
        url.searchParams.set('shop', bridge.shop);
        url.searchParams.set('host', bridge.host);
        bridge.Redirect.url(url.toString());
    }
});

export default api;