





















































import { Component, Vue, Prop } from "vue-property-decorator";
import PostPopupPreview from "@/pages/Settings/components/PostPopupPreview.vue";

@Component({
    components: {PostPopupPreview}
})
export default class PostSettings extends Vue {
    @Prop() readonly object?: any;

    get showPopupOptions() {
        return this.object.post_action === 'popup';
    }
    get showProductPopupOptions() {
        return this.showPopupOptions && this.object.product_action === 'popup';
    }
}
