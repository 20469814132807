















import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
    components: {}
})
export default class CSSSettings extends Vue {
    @Prop() readonly object?: any;
}
