import Vue from 'vue';
import Vuex from 'vuex';
import api from './api';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: null,
        configuration: null,
        configurationOptions: null,
        accounts: null
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setConfiguration(state, configuration) {
            state.configuration = configuration;
        },
        setConfigurationOptions(state, configurationOptions) {
            state.configurationOptions = configurationOptions;
        },
        setAccounts(state, accounts) {
            state.accounts = accounts;
        }
    },
    actions: {
        async getUser({commit}) {
            const user = await api.get('/shop/');
            await commit('setUser', user);
        },
        async getConfiguration({commit}) {
            const configuration = await api.get('/configuration/');
            commit('setConfiguration', configuration);
        },
        async getConfigurationOptions({commit}) {
            const options = await api.options('/configuration/');
            commit('setConfigurationOptions', options);
        },
        async saveConfiguration({commit}, configuration) {
            const response = await api.patch('/configuration/', configuration);
            commit('setConfiguration', response);
        },
        async getAccounts({commit}) {
            const accounts = await api.get('/accounts/');
            commit('setAccounts', accounts);
        },
        async deleteAccount({commit, dispatch}, account) {
            await api.delete(`/accounts/${account.id}/`);
            await dispatch('getAccounts');
        }
    },
    getters: {
        user: state => state.user,
        configuration: state => state.configuration,
        configurationOptions: state => {
            try {
                // @ts-ignore
                return Object.assign({}, ...state.configurationOptions![0].map((field: any) => ({[field.key]: field})));
            } catch (e) {
                return null;
            }
        },
        accounts: state => state.accounts
    }
})
